<template>
  <div>
    <r-combine-target
      v-show="selectedItem==='r_combine_target'"
      @reset="reset"
      @is-combined="isCombined"
      :minNumericVariable="3"
      :minSample="5"
      :nbDataset="2"
      :analysisObj="getParentCombined()"
    />
    <r-plsda-step
      v-show="selectedItem==='r_plsda'"
      :analysisObj="getAnalysisForStep('r_plsda')"
      :dataset.sync="combinedObjectName"
      @is-computed="isComputed"
      ref="plsdastep"
    >

    </r-plsda-step>
    <r-plotind
      v-show="selectedItem==='r_plotind'"
      ref="plotind"
      analysisType="plsda"
      :datasetName="objectName"
      :nbCP="nAxes" />
    <r-plotvar
      v-show="selectedItem==='r_plotvar'"
      analysisType="plsda"
      ref="plotvar"
      :datasetName="objectName"
      :nbCP="nAxes" />
    <r-extract-obj
      v-if="selectedItem==='r_extract_obj'"
      analysisType="plsda"
      :extractSuffix="combinedSuffix"
      :datasetName="objectName"
      datasetPrefix="PLSDA"
      :nbCP="nAxes" />
  </div>
</template>

<script>
import { menu } from '@/mixins/menu'
import { workflow } from '@/mixins/workflow'
import { useMenuStore } from '@/stores/menu'
import { useWorkspaceStore } from '@/stores/workspacestore'
export default {
  name: 'RPLSDA',
  components: {
    'r-plotind': () => import('@/components/ranalyses/RPlotind.vue'),
    'r-plotvar': () => import('@/components/ranalyses/RPlotvar.vue'),
    'r-combine-target': () => import('@/components/ranalyses/RCombineTarget.vue'),
    'r-extract-obj': () => import('@/components/ranalyses/RExtractObj.vue'),
    'r-plsda-step': () => import('@/components/ranalyses/RPLSDAStep.vue')
  },
  mixins: [menu, workflow],
  data () {
    return {
      menuItems: [
        {
          title: 'Preprocessing',
          name: 'r_combine_target',
          disabled: false
        },
        {
          title: 'Run PLS-DA',
          name: 'r_plsda',
          disabled: true
        },
        {
          title: 'Explore individuals',
          name: 'r_plotind',
          disabled: true
        },
        {
          title: 'Explore variables',
          name: 'r_plotvar',
          disabled: true
        },
        {
          title: 'Extract new data',
          name: 'r_extract_obj',
          disabled: true
        }
      ],
      combinedObjectName: null,
      combinedSuffix: '',
      objectName: null
    }
  },
  setup () {
    const workspaceStore = useWorkspaceStore()
    const menuStore = useMenuStore()
    return { workspaceStore, menuStore }
  },
  computed: {
    analyses: function () {
      return this.workspaceStore.analyses
    },
    nAxes: function () {
      let nAxes = 0
      let analysis = this.analyses.find(obj => obj.object_name === this.objectName)
      if (analysis !== undefined) {
        nAxes = Number.parseInt(analysis.meta.func_args.ncp)
      }
      return nAxes
    }
  },
  methods: {
    reset: function (combinedObj) {
      this.combinedObjectName = null
      this.combinedSuffix = null
      this.menuItems[1].disabled = true
      this.menuItems[2].disabled = true
      this.menuItems[3].disabled = true
      this.menuItems[4].disabled = true
      this.objectName = null
    },
    isCombined: function (combinedObj) {
      this.combinedObjectName = combinedObj.combinedObjectName
      this.combinedSuffix = combinedObj.combinedDatasetsUserNames.join('_')
      this.menuItems[1].disabled = false
      this.menuItems[2].disabled = true
      this.menuItems[3].disabled = true
      this.menuItems[4].disabled = true
      this.$refs.plsdastep.reset()
      this.objectName = null
    },
    isComputed: function (plsObj) {
      this.objectName = plsObj
      this.menuItems[1].disabled = false
      this.menuItems[2].disabled = false
      this.menuItems[3].disabled = false
      this.menuItems[4].disabled = false
      this.menuStore.selectItem(this.menuItems[1])
    }
  },
  mounted () {
    if (this.analysisObj !== null) {
      this.objectName = this.analysisObj.object_name
      this.menuItems[1].disabled = false
      this.menuItems[2].disabled = false
      this.menuItems[3].disabled = false
      this.menuItems[4].disabled = false
    }
  }
}
</script>
